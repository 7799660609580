import { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { Popover } from '@headlessui/react';

import { ReactComponent as FilterListIcon } from 'assets/icons/filter_list.svg';
import { ReactComponent as CollapseIcon } from 'assets/icons/chevron-left.svg';
import { PRACTITIONER_FILTER_OPTIONS } from 'utils/constants';

interface SidePanelProps {
  options: { [key: string]: string };
  isCollapsed: boolean;
  onCollapsedIconClicked: () => void;
  selectedFilter: string;
  onFilterOptionClicked: (option: string) => void;
  isOptionDropdownDisabled: (option: string) => boolean;
  numberOfFilters: number;
  title: string;
  onAddClick?: () => void;
}

const { ALL } = PRACTITIONER_FILTER_OPTIONS;

const SidePanel: FC<SidePanelProps> = ({
  isCollapsed,
  isOptionDropdownDisabled,
  onCollapsedIconClicked,
  selectedFilter,
  onFilterOptionClicked,
  numberOfFilters,
  title,
  children,
  options,
  onAddClick,
}) => {
  return (
    <div
      className={`transition-[width] duration-300 ${
        isCollapsed ? 'w-[10rem]' : 'w-[33.9rem]'
      }`}
    >
      <div className="sticky top-[6rem] h-[calc(100vh-6rem)] border-r border-light-grey text-darkest-grey text-14 leading-21">
        <div className="flex h-8.4 items-center border-b border-light-grey relative">
          <button
            data-for={'panel-expand-collapse-button'}
            data-tip=""
            className="w-2.4 h-2.4 bg-white rounded-full flex items-center justify-center shadow-primary absolute -right-1 top-0.8"
            onClick={onCollapsedIconClicked}
          >
            <CollapseIcon
              className={`w-1.4 h-1.4 transition-transform duration-300 ${
                isCollapsed && 'rotate-180'
              }`}
            />
          </button>

          <ReactTooltip
            id={'panel-expand-collapse-button'}
            place="right"
            effect="solid"
            className="!border-light-grey !rounded-[0.8rem] !h-2.6 !p-0.8 flex justify-center items-center z-[100] !bg-white shadow-tooltip"
          >
            <span className="text-11 text-darkest-grey flex h-full items-center">
              {isCollapsed ? 'Expand' : 'Collapse'}
            </span>
          </ReactTooltip>

          <span
            className={`flex w-full leading-[1.8rem] text-11 absolute top-5.5 ${
              isCollapsed ? 'justify-center left-0' : 'left-3.9'
            }`}
          >
            {selectedFilter} ({numberOfFilters})
          </span>

          <div className="flex gap-x-1 items-center pl-3.9">
            <h3
              className={`leading-[3.6rem] text-24 font-bold  ${
                isCollapsed ? 'hidden' : 'block'
              }`}
            >
              {title}
            </h3>
            <Popover className="relative">
              {({ open }) => {
                const isFilterNotDefault = selectedFilter !== ALL;
                return (
                  <>
                    <Popover.Button className="flex items-center group">
                      <FilterListIcon
                        className={`w-2.4 h-2.4 group-hover:all-child:all-child:fill-magenta ${
                          open || isFilterNotDefault
                            ? 'all-child:all-child:fill-magenta'
                            : ''
                        }`}
                      />
                    </Popover.Button>
                    <Popover.Panel className="w-11.2 flex flex-col gap-y-1.5 mt-0.6 py-1.6 absolute -left-4 z-10 bg-white shadow-primary rounded-[1rem] font-normal">
                      {Object.values(options).map((option) => (
                        <Popover.Button
                          key={option}
                          className={`w-full pl-2 text-left hover:text-magenta ${
                            selectedFilter === option
                              ? 'text-magenta'
                              : 'text-darkest-grey'
                          } disabled:text-grey`}
                          onClick={() => onFilterOptionClicked(option)}
                          disabled={isOptionDropdownDisabled(option)}
                        >
                          {option}
                        </Popover.Button>
                      ))}
                    </Popover.Panel>
                  </>
                );
              }}
            </Popover>
          </div>
        </div>
        <div
          className="overflow-y-auto h-[calc(100%-8.4rem)] scrollbar"
          key={selectedFilter}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default SidePanel;
